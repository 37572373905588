import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TestimonialsState } from './state';

const defaultState: TestimonialsState = {
  testimonialsResponse: {
    current: 0,
    count: 0,
    next: 0,
    previous: 0,
    total_count: 0,
    avg_mark: 0,
    results: [],
  },
};

export const testimonialsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};