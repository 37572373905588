import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IFooterMenu, IMenuItem, IPage, IUserProfile } from '@/interfaces';


export const mutations = {
    setToken(state: MainState, payload: string) {
        state.token = payload;
    },
    setLoggedIn(state: MainState, payload: boolean) {
        state.isLoggedIn = payload;
    },
    setLogInError(state: MainState, payload: boolean) {
        state.logInError = payload;
    },
    setUserProfile(state: MainState, payload: IUserProfile) {
        state.userProfile = payload;
    },
    setPageDetail(state: MainState, payload: IPage | null) {
        state.pageDetail = payload;
    },
    setFooterMenuItemList(state: MainState, payload: IFooterMenu) {
        state.footerMenuItemList = payload;
    },
    setCartSidebarShow(state: MainState, payload: boolean) {
        state.cartSidebarShow = payload;
    },
    setMainMenuItemList(state: MainState, payload: IMenuItem[]) {
        state.mainMenuItemList = payload;
    },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitSetPageDetail = commit(mutations.setPageDetail);
export const commitSetFooterMenuItemList = commit(mutations.setFooterMenuItemList);
export const commitSetCartSidebarShow = commit(mutations.setCartSidebarShow);
export const commitSetMainMenuItemList = commit(mutations.setMainMenuItemList);
