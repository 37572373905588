import { TestimonialsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    testimonialsResponse: (state: TestimonialsState) => state.testimonialsResponse,
};

const {read} = getStoreAccessors<TestimonialsState, State>('');

export const readTestimonialsResponse = read(getters.testimonialsResponse);
