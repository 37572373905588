import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ProductsState } from './state';

const defaultState: ProductsState = {
  productsList: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total: 0,
    total_count: 0,
    max_price: 0,
    min_price: 0,
    results: [],
    breadcrumbs: [],
  },
  productDetail: null,
  categoriesList: {
    categories: [],
    parent: null,
    available_filters: [],
  },
  invoiceDetail: null,
  productsInvoice: null,
  productsInWishlist: [],
  categoriesSubscriptions: [],
  compareCount: 0,
};

export const productsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};