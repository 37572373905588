import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { SpecialOffersState } from './state';

const defaultState: SpecialOffersState = {
  personalDiscountsResponse: {
    total_purchases_amount: 0,
    personal_discount: 0,
    discounts: [],
  },
};

export const specialOffersModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};