import { SpecialOffersState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IPersonalDiscountsResponse } from '@/interfaces/special_offers';

export const mutations = {
    setPersonalDiscountsResponse(state: SpecialOffersState, payload: IPersonalDiscountsResponse) {
        state.personalDiscountsResponse = payload;
    },
};

const {commit} = getStoreAccessors<SpecialOffersState | any, State>('');

export const commitSetPersonalDiscountsResponse = commit(mutations.setPersonalDiscountsResponse);
