import { SpecialOffersState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    personalDiscountsResponse: (state: SpecialOffersState) => state.personalDiscountsResponse,
};

const {read} = getStoreAccessors<SpecialOffersState, State>('');

export const readPersonalDiscountsResponse = read(getters.personalDiscountsResponse);
