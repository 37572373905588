import { api } from '@/api';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { dispatchCheckApiError } from '../main/actions';
import { State } from '../state';
import { TestimonialsState } from './state';
import { commitSetTestimonialsResponse } from './mutations';

type MainContext = ActionContext<TestimonialsState, State>;

export const actions = {
    async actionGetTestimonialsList(
        context: MainContext, payload: { data: { product_id?: number | null, user_id?: number | null }, rowQuery: string },
    ) {
        try {
            const response = await api.getTestimonialsList(context.rootState.main.token, payload.data, payload.rowQuery);
            commitSetTestimonialsResponse(context, response.data);
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            commitSetTestimonialsResponse(context, {
                current: 0,
                count: 0,
                next: 0,
                previous: 0,
                total_count: 0,
                avg_mark: 0,
                results: [],
            });
        }
    },
    async actionSubmitTestimonial(
        context: MainContext, payload: { product: number, text: string, mark: number },
    ) {
        try {
            const response = await api.submitTestimonial(context.rootState.main.token, payload);
            return true;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<TestimonialsState | any, State>('');

export const dispatchGetTestimonialsList = dispatch(actions.actionGetTestimonialsList);
export const dispatchSubmitTestimonial = dispatch(actions.actionSubmitTestimonial);
