import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    loginError: (state: MainState) => state.logInError,
    userProfile: (state: MainState) => state.userProfile,
    token: (state: MainState) => state.token,
    isLoggedIn: (state: MainState) => state.isLoggedIn,
    firstNotification: (state: MainState) => state.notifications.length > 0 && state.notifications[0],
    pageDetail: (state: MainState) => state.pageDetail,
    footerMenuItemList: (state: MainState) => state.footerMenuItemList,
    cartSidebarShow: (state: MainState) => state.cartSidebarShow,
    mainMenuItemList: (state: MainState) => state.mainMenuItemList,
};

const {read} = getStoreAccessors<MainState, State>('');

export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readPageDetail = read(getters.pageDetail);
export const readFooterMenuItemList = read(getters.footerMenuItemList);
export const readCartSidebarShow = read(getters.cartSidebarShow);
export const readMainMenuItemList = read(getters.mainMenuItemList);
