import { api } from '@/api';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { dispatchCheckApiError } from '../main/actions';
import { State } from '../state';
import { NewsState } from './state';
import store from '../../store';
import { commitSetNewsArticle, commitSetNewsList } from './mutations';

type MainContext = ActionContext<NewsState, State>;

export const actions = {
    async actionGetNewsList(context: MainContext, payload: { rowQuery: string }) {
        try {
            const response = await api.getNewsList(context.rootState.main.token, payload.rowQuery);
            commitSetNewsList(context, response.data);
        } catch (err) {
            await dispatchCheckApiError(context, err);
        }
    },
    async actionGetNewsArticle(context: MainContext, payload: { article_id: number }) {
        try {
            const response = await api.getNewsArticle(context.rootState.main.token, payload);
            commitSetNewsArticle(context, response.data);
        } catch (err) {
            commitSetNewsArticle(context, null);
            await dispatchCheckApiError(context, err);
        }
    },
};

const { dispatch } = getStoreAccessors<NewsState | any, State>('');

export const dispatchGetNewsList = dispatch(actions.actionGetNewsList);
export const dispatchGetNewsArticle = dispatch(actions.actionGetNewsArticle);