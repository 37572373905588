import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vuetify';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueCarousel from 'vue-carousel';
import StarRating from 'vue-star-rating';
import vSelect from "vue-select";
import VueClipboard from 'vue-clipboard2';
import vueDebounce from 'vue-debounce';
import ToastPlugin from 'vue-toast-notification';
import DatePicker from 'vue2-datepicker';
import VueSlider from 'vue-slider-component';
import ImageMagnifier from 'vue-image-magnifier';
import moment from 'moment';
import VueI18n from 'vue-i18n';
import {defaultLocale, messages} from '@/i18n';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import VueMarquee from 'vue-marquee-component';
import { Icon } from 'leaflet';
import { vMaska } from 'maska';
import VuePhoneNumberInput from 'vue-phone-number-input';
import VueSwal from 'vue-swal';


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import 'leaflet/dist/leaflet.css';
import './assets/css/custom.css';
import './assets/fonts/Raleway/stylesheet.css';
import './assets/fonts/Montserrat/stylesheet.css';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/uk';
import 'vue2-datepicker/locale/en';
import 'vue-select/dist/vue-select.css';
import 'vue-slider-component/theme/default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'animate.css';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';


Vue.config.productionTip = false;
Vue.use(VueCarousel);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ImageMagnifier);
Vue.use(VueI18n);
Vue.use(VueClipboard);
Vue.use(ToastPlugin);
Vue.use(vueDebounce);
Vue.use(VueMarquee);
Vue.use(VueSwal);
// Vue.use(vMaska);
Vue.use(require('vue-moment'));
Vue.component('star-rating', StarRating);
Vue.component('datepicker', DatePicker);
Vue.component('v-select', vSelect);
Vue.component('VueSlider', VueSlider);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);


export const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.mixin({
  methods: {
    convertDateToStr(date) {
      return moment(String(date)).format('DD/MM/YYYY');
    },
    convertTimeToStr(date) {
      return moment(String(date)).format('HH:mm');
    },
    convertDateTimeToStr(date) {
      return moment(String(date)).format('DD/MM/YYYY HH:mm');
    },
    convertDateTimeToStrCustom(date, format) {
      return moment(String(date)).format(format);
    },
    copyToClipboard(value, successMsg) {
      this.$copyText(value.toString()).then((e) => {
        // @ts-ignore
        this.$toast.success(this.$t('Скопировано'), {
          duration: 2000, position: 'bottom',
        });
      }, (e) => {
        // @ts-ignore
        this.$toast.error(this.$t('Не получилось скопировать'), {
          duration: 2000, position: 'bottom',
        });
      });
    },
    animateElement(elementId, animationName) {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.remove(animationName);
        setTimeout(() => {
          element.classList.add(animationName);
        }, 50);
      }
    },
  }
})

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');