import { ProductsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IInvoiceDetail, IProductDetail, IProductsCategoriesResponse, IProductsListResponse } from '@/interfaces/products';

export const mutations = {
    setProductsList(state: ProductsState, payload: IProductsListResponse) {
        state.productsList = payload;
    },
    setProductDetail(state: ProductsState, payload: IProductDetail | null) {
        state.productDetail = payload;
    },
    setCategoriesList(state: ProductsState, payload: IProductsCategoriesResponse) {
        state.categoriesList = payload;
    },
    setInvoiceDetail(state: ProductsState, payload: IInvoiceDetail | null) {
        state.invoiceDetail = payload;
    },
    setProductsInvoice(state: ProductsState, payload: number | null) {
        state.productsInvoice = payload;
    },
    setProductsInWishlist(state: ProductsState, payload: number[]) {
        state.productsInWishlist = payload;
    },
    setCategoriesSubscriptions(state: ProductsState, payload: number[]) {
        state.categoriesSubscriptions = payload;
    },
    setCompareCount(state: ProductsState, payload: number) {
        state.compareCount = payload;
    },
};

const {commit} = getStoreAccessors<ProductsState | any, State>('');

export const commitSetProductsList = commit(mutations.setProductsList);
export const commitSetProductDetail = commit(mutations.setProductDetail);
export const commitSetCategoriesList = commit(mutations.setCategoriesList);
export const commitSetInvoiceDetail = commit(mutations.setInvoiceDetail);
export const commitSetProductsInvoice = commit(mutations.setProductsInvoice);
export const commitSetProductsInWishlist = commit(mutations.setProductsInWishlist);
export const commitSetCategoriesSubscriptions = commit(mutations.setCategoriesSubscriptions);
export const commitSetCompareCount = commit(mutations.setCompareCount);
