import { Locales } from './locales';

import en from './en.json';
import ru from './ru.json';
import uk from './uk.json';

export const messages = {
  [Locales.EN]: en,
  [Locales.RU]: ru,
  [Locales.UA]: uk,
};

export const defaultLocale = Locales.RU;
