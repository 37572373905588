import { api } from '@/api';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { dispatchCheckApiError } from '../main/actions';
import { State } from '../state';
import { SpecialOffersState } from './state';
import { commitSetPersonalDiscountsResponse } from './mutations';
import { IFeedbackForm } from '@/interfaces';

type MainContext = ActionContext<SpecialOffersState, State>;

export const actions = {
    async actionGetMyPersonalDiscounts(context: MainContext) {
        try {
            const response = await api.getMyPersonalDiscounts(context.rootState.main.token);
            commitSetPersonalDiscountsResponse(context, response.data);
        } catch (err) {
            await dispatchCheckApiError(context, err);
            commitSetPersonalDiscountsResponse(context, {
                total_purchases_amount: 0,
                personal_discount: 0,
                discounts: [],
            });
        }
    },
    async actionSubmitSendFeedback(context: MainContext, payload: IFeedbackForm) {
        try {
            const response = await api.submitSendFeedback(context.rootState.main.token, payload);
            return true;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<SpecialOffersState | any, State>('');

export const dispatchGetMyPersonalDiscounts = dispatch(actions.actionGetMyPersonalDiscounts);
export const dispatchSubmitSendFeedback = dispatch(actions.actionSubmitSendFeedback);
