import { TestimonialsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { ITestimonialsResponse } from '@/interfaces/testimonials';

export const mutations = {
    setTestimonialsResponse(state: TestimonialsState, payload: ITestimonialsResponse) {
        state.testimonialsResponse = payload;
    },
};

const {commit} = getStoreAccessors<TestimonialsState | any, State>('');

export const commitSetTestimonialsResponse = commit(mutations.setTestimonialsResponse);
