import { NewsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { INewsArticle, INewsListResponse } from '@/interfaces/news';

export const mutations = {
    setNewsList(state: NewsState, payload: INewsListResponse) {
        state.newsList = payload;
    },
    setNewsArticle(state: NewsState, payload: INewsArticle | null) {
        state.newsArticle = payload;
    },
};

const {commit} = getStoreAccessors<NewsState | any, State>('');

export const commitSetNewsList = commit(mutations.setNewsList);
export const commitSetNewsArticle = commit(mutations.setNewsArticle);