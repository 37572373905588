export const getLocalToken = () => localStorage.getItem('token');
export const saveLocalToken = (token: string) => localStorage.setItem('token', token);
export const removeLocalToken = () => localStorage.removeItem('token');

export const getLocalSessionId = () => localStorage.getItem('anon_session_id');
export const saveLocalSessionId = (sessionId: string) => localStorage.setItem('anon_session_id', sessionId);

export const getLocalLanguage = () => localStorage.getItem('front_language');
export const saveLocalLanguage = (lang: string) => localStorage.setItem('front_language', lang);

export const getLocalCompareProducts = () => localStorage.getItem('compare_products_list');
export const saveLocalCompareProducts = (value: string) => localStorage.setItem('compare_products_list', value);

export const getLocalSearchHistory = () => localStorage.getItem('search_history');
export const saveLocalSearchHistory = (value: string) => localStorage.setItem('search_history', value);

export const getLocalCookiesConfirm = () => localStorage.getItem('cookies_confirm');
export const saveLocalCookiesConfirm = (value: string) => localStorage.setItem('cookies_confirm', value);
