import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('./views/main/Start.vue'),
      children: [
        {
          path: '/',
          component: () => import('./views/main/MainRoot.vue'),
          children: [
            // {
            //   path: 'redirect-page',
            //   component: () => import('./views/RedirectPage.vue'),
            // },
            {
              path: 'home',
              component: () => import('./views/main/home/HomePage.vue'),
            },
            {
              path: 'my-cabinet',
              component: () => import('./views/main/cabinet/Cabinet.vue'),
            },
            {
              path: 'about',
              component: () => import('./views/main/home/About.vue'),
            },
            {
              path: 'delivery/info',
              component: () => import('./views/main/home/DeliveryInfo.vue'),
            },
            {
              path: 'return/info',
              component: () => import('./views/main/home/ReturnInfo.vue'),
            },
            {
              path: 'page/:slug',
              component: () => import('./views/main/home/Page.vue'),
            },
            {
              path: 'product/view',
              component: () => import('./views/main/products/ProductView.vue'),
            },
            {
              path: 'search-results',
              component: () => import('./views/main/products/SearchResultsList.vue'),
            },
            {
              path: 'catalog/:codename',
              component: () => import('./views/main/products/CatalogList.vue'),
            },
            {
              path: 'catalog/product/:codename',
              component: () => import('./views/main/products/Product.vue'),
            },
            {
              path: '/order/cart',
              component: () => import('./views/main/order/Cart.vue'),
            },
            {
              path: '/order/checkout',
              component: () => import('./views/main/order/Checkout.vue'),
            },
            // {
            //   path: '/order/thanks',
            //   component: () => import('./views/main/order/Thanks.vue'),
            // },

            {
              path: 'category/popular/list',
              component: () => import('./views/main/lists/PopularCategoriesList.vue'),
            },
            {
              path: 'category/list',
              component: () => import('./views/main/lists/CategoriesList.vue'),
            },
            {
              path: 'category/list/:codename',
              component: () => import('./views/main/lists/CategoriesList.vue'),
            },
            {
              path: 'latest_view/list',
              component: () => import('./views/main/lists/LatestViewList.vue'),
            },
            {
              path: 'news/list',
              component: () => import('./views/main/lists/NewsList.vue'),
            },
            {
              path: 'products/by_tag/list/:tag',
              component: () => import('./views/main/lists/ProductByTagList.vue'),
            },
            {
              path: 'products/kinds/list/:id',
              component: () => import('./views/main/products/ProductsKindsList.vue'),
            },
            {
              path: 'products/popular_kinds/list',
              component: () => import('./views/main/lists/PopularKindsProductsList.vue'),
            },
            {
              path: 'news/:id',
              component: () => import('./views/main/home/News.vue'),
            },
            {
              path: 'wishlist',
              component: () => import('./views/main/lists/Wishlist.vue'),
            },
            {
              path: 'compare-list',
              component: () => import('./views/main/products/CompareList.vue'),
            },

            {
              path: 'product/list',
              component: () => import('./views/main/products/ProductsList.vue'),
            },
            {
              path: 'product/view',
              component: () => import('./views/main/products/ProductView.vue'),
            },
            {
              path: 'front/order/contact',
              component: () => import('./views/main/order/Contact.vue'),
            },
            {
              path: 'front/order/delivery',
              component: () => import('./views/main/order/Delivery.vue'),
            },
            {
              path: 'front/order/payment',
              component: () => import('./views/main/order/Payment.vue'),
            },
            {
              path: '404-not-found',
              component: () => import('./views/main/home/Error.vue'),
            },
            {
              path: 'brand/:codename',
              component: () => import('./views/main/products/BrandView.vue'),
            },


            {
              path: 'login',
              component: () => import('./views/main/login/LogIn.vue'),
            },
            {
              path: 'recover-password',
              component: () => import('./views/main/password-reset/PasswordRecovery.vue'),
            },
            {
              path: 'password-reset/:resetToken',
              component: () => import('./views/main/password-reset/PasswordReset.vue'),
            },
            {
              path: 'sign-up',
              component: () => import('./views/main/sign-up/SignUp.vue'),
            },
            {
              path: 'sign-up/select-type/',
              component: () => import('./views/main/sign-up/SignUpType.vue'),
            },
            {
              path: 'sign-up/client/',
              component: () => import('./views/main/sign-up/SignUpClient.vue'),
            },
            {
              path: 'sign-up/nursery',
              component: () => import('./views/main/sign-up/SignUpNursery.vue'),
            },
            {
              path: 'sign-up/wholesaler',
              component: () => import('./views/main/sign-up/SignUpWholesaler.vue'),
            },
            {
              path: 'sign-up/front-3',
              component: () => import('./views/main/login/SignUpStep3.vue'),
            },
            {
              path: 'external-integrations/auth-google',
              component: () => import('./views/main/external-integrations/AuthGoogle.vue'),
            },
            // {
            //   path: 'external-integrations/auth-facebook',
            //   component: () => import('./views/main/external-integrations/AuthFacebook.vue'),
            // },
          ],
        },
        // {
        //   path: 'cabinet',
        //   component: () => import('./views/main/home/MyCabinet.vue'),
        // },
      ],
    },
    {
      path: '/*', redirect: '/page/404-error',
    },
  ],
});
