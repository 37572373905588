import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { mainModule } from './main';
import { newsModule } from './news';
import { productsModule } from './products';
import { testimonialsModule } from './testimonials';
import { specialOffersModule } from './special_offers';
import { State } from './state';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    news: newsModule,
    products: productsModule,
    testimonials: testimonialsModule,
    specialOffers: specialOffersModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
