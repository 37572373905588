import { ProductsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    productsList: (state: ProductsState) => state.productsList,
    productDetail: (state: ProductsState) => state.productDetail,
    categoriesList: (state: ProductsState) => state.categoriesList,
    invoiceDetail: (state: ProductsState) => state.invoiceDetail,
    productsInvoice: (state: ProductsState) => state.productsInvoice,
    productsInWishlist: (state: ProductsState) => state.productsInWishlist,
    categoriesSubscriptions: (state: ProductsState) => state.categoriesSubscriptions,
    compareCount: (state: ProductsState) => state.compareCount,
};

const {read} = getStoreAccessors<ProductsState, State>('');

export const readProductsList = read(getters.productsList);
export const readProductDetail = read(getters.productDetail);
export const readProductsCategoriesList = read(getters.categoriesList);
export const readInvoiceDetail = read(getters.invoiceDetail);
export const readProductsInvoice = read(getters.productsInvoice);
export const readProductsInWishlist = read(getters.productsInWishlist);
export const readCategoriesSubscriptions = read(getters.categoriesSubscriptions);
export const readCompareCount = read(getters.compareCount);
