
import { Component, Vue } from 'vue-property-decorator';
import NotificationsManager from '@/components/NotificationsManager.vue';
import { readIsLoggedIn, readUserProfile } from '@/store/main/getters';
import {commitSetLoggedIn} from '@/store/main/mutations';
import { RouterLink } from 'vue-router';
import { getLocalLanguage, getLocalSessionId, saveLocalSessionId } from './utils';

@Component({
  components: {
    NotificationsManager,
  },
})
export default class App extends Vue {

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public makeToken(length) {
    let result  = '';
    const characters  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public async created() {
    const localSessionId = getLocalSessionId();
    if (!localSessionId) {
      saveLocalSessionId(await this.makeToken(40));
    }
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.$root.$i18n.locale = userProfile && userProfile.language ? userProfile.language : 'uk'; 
    } else {
      const lang = getLocalLanguage();
      this.$root.$i18n.locale = lang ? lang : 'uk';
    }
  }
}
