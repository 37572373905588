export enum Locales {
  EN = 'en',
  RU = 'ru',
  UA = 'uk',
}

export const LOCALES = [
  { value: Locales.EN, caption: 'English' },
  { value: Locales.RU, caption: 'Русский' },
  { value: Locales.UA, caption: 'Українська' },
];
