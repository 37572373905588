import { api } from '@/api';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { dispatchCheckApiError } from '../main/actions';
import { State } from '../state';
import { ProductsState } from './state';
import store from '../../store';
import { commitSetCategoriesList, commitSetCategoriesSubscriptions, commitSetInvoiceDetail, commitSetProductDetail, commitSetProductsInWishlist, commitSetProductsList } from './mutations';

type MainContext = ActionContext<ProductsState, State>;

export const actions = {
    async actionGetProductsFilters(context: MainContext, payload: any) {
        try {
            const response = await api.getProductsFilters(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionGetProductsInWishlist(context: MainContext, payload: {anon_session_id?: string | null}) {
        try {
            const response = await api.getProductsInWishlist(context.rootState.main.token, payload);
            commitSetProductsInWishlist(context, response.data);
        } catch (err) {
            commitSetProductsInWishlist(context, []);
        }
    },
    async actionToggleProductInWishlist(
        context: MainContext,
        payload: {
            anon_session_id?: string | null, product_id: number, action: string,
        },
    ) {
        try {
            const response = await api.toggleProductInWishlist(context.rootState.main.token, payload);
            dispatchGetProductsInWishlist(context, payload);
            return true;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return false;
        }
    },
    async actionGetCategoriesSubscriptionsList(
        context: MainContext, payload: { data: any, rowQuery: string },
    ) {
        try {
            const response = await api.getCategoriesSubscriptionsList(
                context.rootState.main.token, payload.data, payload.rowQuery,
            );
            return response.data;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return {
                count: 0,
                next: 0,
                previous: 0,
                total: 0,
                total_count: 0,
                results: [],
            };
        }
    },
    async actionGetCategoriesSubscriptionsIDList(context: MainContext, payload: any) {
        try {
            const response = await api.getCategoriesSubscriptionsIDList(context.rootState.main.token, payload);
            commitSetCategoriesSubscriptions(context, response.data);
        } catch (err) {
            commitSetCategoriesSubscriptions(context, []);
        }
    },
    async actionToggleCategorySubscription(
        context: MainContext,
        payload: {
            category_id: number, action: string,
        },
    ) {
        try {
            const response = await api.toggleCategorySubscription(context.rootState.main.token, payload);
            dispatchGetCategoriesSubscriptionsIDList(context, payload);
            return true;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return false;
        }
    },
    async actionGetProductsList(
        context: MainContext,
        payload: {
            data: {
                category?: string, filters?: any,
                tag?: string, brand?: string, order_by?: string,
                price_range?: any,
            }, rowQuery: string,
        },
    ) {
        try {
            const response = await api.getProductsList(
                context.rootState.main.token, payload.data, payload.rowQuery,
            );
            commitSetProductsList(context, response.data);
        } catch (err) {
            await dispatchCheckApiError(context, err);
            commitSetProductsList(context, {
                count: 0,
                next: 0,
                current: 0,
                previous: 0,
                total: 0,
                total_count: 0,
                max_price: 0,
                min_price: 0,
                results: [],
            });
        }
    },
    async actionSearchProductsList(
        context: MainContext,
        payload: {
            data: {
                search: string, order_by?: string,
            }, rowQuery: string,
        },
    ) {
        try {
            const response = await api.searchProductsList(
                context.rootState.main.token, payload.data, payload.rowQuery,
            );
            return response.data;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return {
                count: 0, next: 0, previous: 0, total: 0, total_count: 0, results: [], breadcrumbs: []
            }
        }
    },
    async actionGetCompareProductsList(
        context: MainContext, payload: { compare_products_list: number[] },
    ) {
        try {
            const response = await api.getCompareProductsList(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            return []
        }
    },
    async actionGetProductsWishlist(
        context: MainContext, payload: { data: {anon_session_id?: string | null}, rowQuery: string },
    ) {
        try {
            const response = await api.getProductsWishlist(
                context.rootState.main.token, payload.data, payload.rowQuery,
            );
            commitSetProductsList(context, response.data);
        } catch (err) {
            await dispatchCheckApiError(context, err);
            commitSetProductsList(context, {
                count: 0,
                next: 0,
                current: 0,
                previous: 0,
                total: 0,
                total_count: 0,
                max_price: 0,
                min_price: 0,
                results: [],
            });
        }
    },
    async actionGetProductDetail(
        context: MainContext, payload: {product_codename: string, anon_session_id?: string | null},
    ) {
        try {
            const response = await api.getProductDetail(context.rootState.main.token, payload);
            commitSetProductDetail(context, response.data);
        } catch (err) {
            commitSetProductDetail(context, null);
            // await dispatchCheckApiError(context, err);
        }
    },
    async actionGetProductsViewHistoryList(context: MainContext, payload: any) {
        try {
            const response = await api.getProductsViewHistoryList(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionGetAllProductsCategoriesList(context: MainContext) {
        try {
            const response = await api.getCategoriesList(context.rootState.main.token, {});
            commitSetCategoriesList(context, response.data);
        } catch (err) {
            commitSetCategoriesList(context, {categories: [], parent: null, available_filters: []});
            await dispatchCheckApiError(context, err);
        }
    },
    async actionGetDeliveryOptionsList(context: MainContext) {
        try {
            const response = await api.getDeliveryOptionsList(context.rootState.main.token);
            return response.data;
        } catch (err) {
            return [];
        }
    },
    async actionGetProductsSubCategoriesList(context: MainContext, payload: { category?: string | null }) {
        try {
            const response = await api.getCategoriesList(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return {
                categories: [],
                parent: null,
            };
        }
    },
    async actionGetBrandsList(context: MainContext, payload: any) {
        try {
            const response = await api.getBrandsList(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionGetBrandDetail(context: MainContext, payload: {codename: string}) {
        try {
            const response = await api.getBrandDetail(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return null;
        }
    },
    async actionGetPopularProductsCategoriesList(context: MainContext) {
        try {
            const response = await api.getPopularCategoriesList(context.rootState.main.token);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionGetProductsByTagList(
        context: MainContext, payload: { data: {category?: string, tag?: string}, rowQuery: string },
    ) {
        try {
            const response = await api.getProductsByTagList(context.rootState.main.token, payload.data, payload.rowQuery);
            return response.data.results;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionGetProductsByKindList(context: MainContext, payload: { data: {creature_kind?: number, brand?: string | null, order_by?: any}, rowQuery: string }) {
        try {
            const response = await api.getProductsByKindList(context.rootState.main.token, payload.data, payload.rowQuery);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return {
                count: 0,
                next: 0,
                previous: 0,
                current: 0,
                total: 0,
                total_count: 0,
                results: [],
            };
        }
    },
    async actionGetPopularKindsProductsList(context: MainContext, payload: { brand?: string | null}) {
        try {
            const response = await api.getPopularKindsProductsList(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionGetRecentInvoiceDetails(
        context: MainContext,
        payload: {anon_session_id?: string | null},
    ) {
        try {
            const response = await api.getRecentInvoiceDetails(context.rootState.main.token, payload);
            commitSetInvoiceDetail(context, response.data);
            return response.data.id;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return null;
        }
    },
    async actionGetInvoiceDetails(
        context: MainContext,
        payload: {invoice_id: number, promocode?: string, anon_session_id?: string | null},
    ) {
        try {
            const response = await api.getInvoiceDetails(context.rootState.main.token, payload);
            commitSetInvoiceDetail(context, response.data);
        } catch (err) {
            // await dispatchCheckApiError(context, err);
        }
    },
    async actionSaveInvoiceDetails(context: MainContext, payload: any) {
        try {
            const response = await api.saveInvoiceDetails(context.rootState.main.token, payload);
            commitSetInvoiceDetail(context, response.data);
            return true;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return err.response.data;
        }
    },
    async actionProcessInvoice(context: MainContext, payload: any) {
        try {
            const response = await api.processInvoice(context.rootState.main.token, payload);
            commitSetInvoiceDetail(context, response.data.invoice);
            return response.data
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return null;
        }
    },
    async actionGetOrdersList(context: MainContext, payload: {data: any, rowQuery: string}) {
        try {
            const response = await api.getOrdersList(context.rootState.main.token, payload.data, payload.rowQuery);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return {
                count: 0,
                next: 0,
                previous: 0,
                total: 0,
                total_count: 0,
                results: [],
            };
        }
    },
    async actionGetPaymentMethodsList(context: MainContext) {
        try {
            const response = await api.getPaymentMethodsList(context.rootState.main.token);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionRequestProductPurchase(
        context: MainContext,
        payload: {
            product_property: number, quantity: number,
            invoice?: number | null, anon_session_id?: string | null,
            one_click?: boolean, phone?: string,
        },
    ) {
        try {
            const response = await api.requestProductPurchase(context.rootState.main.token, payload);
            return response.data.id;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return null;
        }
    },
    async actionRequestBuyOneClick(
        context: MainContext, payload: {invoice: number, phone?: string},
    ) {
        try {
            const response = await api.requestBuyOneClick(context.rootState.main.token, payload);
            return response.data.id;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return null;
        }
    },
    async actionToggleProductInCart(
        context: MainContext, payload: {invoice_item: number, action: string, quantity?: number},
    ) {
        try {
            const response = await api.toggleProductInCart(context.rootState.main.token, payload);
            commitSetInvoiceDetail(context, response.data);
        } catch (err) {
            // await dispatchCheckApiError(context, err);
        }
    },
    async actionRemoveFromCart(context: MainContext, payload: { id: number, anon_session_id?: string | null }){
        try {
            const response = await api.removeFromCart(context.rootState.main.token, payload);
            return true;
        } catch (err) {
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<ProductsState | any, State>('');

export const dispatchGetProductsFilters = dispatch(actions.actionGetProductsFilters);
export const dispatchGetProductsList = dispatch(actions.actionGetProductsList);
export const dispatchSearchProductsList = dispatch(actions.actionSearchProductsList);
export const dispatchGetCompareProductsList = dispatch(actions.actionGetCompareProductsList);
export const dispatchGetProductsWishlist = dispatch(actions.actionGetProductsWishlist);
export const dispatchGetProductsInWishlist = dispatch(actions.actionGetProductsInWishlist);
export const dispatchToggleProductInWishlist = dispatch(actions.actionToggleProductInWishlist);
export const dispatchGetProductDetail = dispatch(actions.actionGetProductDetail);
export const dispatchGetProductsViewHistoryList = dispatch(actions.actionGetProductsViewHistoryList);
export const dispatchGetAllProductsCategoriesList = dispatch(actions.actionGetAllProductsCategoriesList);
export const dispatchGetProductsSubCategoriesList = dispatch(actions.actionGetProductsSubCategoriesList);
export const dispatchGetPopularProductsCategoriesList = dispatch(actions.actionGetPopularProductsCategoriesList);
export const dispatchGetRecentInvoiceDetails = dispatch(actions.actionGetRecentInvoiceDetails);
export const dispatchGetProductsByTagList = dispatch(actions.actionGetProductsByTagList);
export const dispatchGetProductsByKindList = dispatch(actions.actionGetProductsByKindList);
export const dispatchGetPopularKindsProductsList = dispatch(actions.actionGetPopularKindsProductsList);
export const dispatchGetInvoiceDetails = dispatch(actions.actionGetInvoiceDetails);
export const dispatchSaveInvoiceDetails = dispatch(actions.actionSaveInvoiceDetails);
export const dispatchRequestProductPurchase = dispatch(actions.actionRequestProductPurchase);
export const dispatchRequestBuyOneClick = dispatch(actions.actionRequestBuyOneClick);
export const dispatchToggleProductInCart = dispatch(actions.actionToggleProductInCart);
export const dispatchRemoveFromCart = dispatch(actions.actionRemoveFromCart);
export const dispatchGetOrdersList = dispatch(actions.actionGetOrdersList);
export const dispatchGetDeliveryOptionsList = dispatch(actions.actionGetDeliveryOptionsList);
export const dispatchProcessInvoice = dispatch(actions.actionProcessInvoice);
export const dispatchGetPaymentMethodsList = dispatch(actions.actionGetPaymentMethodsList);
export const dispatchGetCategoriesSubscriptionsList = dispatch(actions.actionGetCategoriesSubscriptionsList);
export const dispatchGetCategoriesSubscriptionsIDList = dispatch(actions.actionGetCategoriesSubscriptionsIDList);
export const dispatchToggleCategorySubscription = dispatch(actions.actionToggleCategorySubscription);
export const dispatchGetBrandsList = dispatch(actions.actionGetBrandsList);
export const dispatchGetBrandDetail = dispatch(actions.actionGetBrandDetail);
