import { NewsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    newsList: (state: NewsState) => state.newsList,
    newsArticle: (state: NewsState) => state.newsArticle,
};

const {read} = getStoreAccessors<NewsState, State>('');

export const readNewsList = read(getters.newsList);
export const readNewsArticle = read(getters.newsArticle);